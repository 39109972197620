import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core'
import { Router } from '@angular/router'
import { App } from '@capacitor/app'
import {
  faBars,
  faHome,
  IconDefinition,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import { ActionSheetController, NavController } from '@ionic/angular'
import { ActionSheetButton } from '@ionic/core'
import { TranslateService } from '@ngx-translate/core'
import { Select, Store } from '@ngxs/store'
import { Observable, Subject } from 'rxjs'
import { filter, takeUntil, tap } from 'rxjs/operators'
import { MenuObject } from './interfaces/content'
import { LinkService } from './services/link.service'
import { StorageService } from './services/storage.service'
import { ToggleHelpIsActive } from './store/actions/content.actions'
import { MenuState } from './store/state/menu.state'
import { FeatureFlagService } from 'src/app/services/feature-flag.service'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'
import { Keepalive } from '@ng-idle/keepalive'
import { PopoverController } from '@ionic/angular'
import { MuKeepaliveComponent } from './ui-components/features/keepalive/keepalive.component'
import { environment } from 'src/environments/environment'
import { EventLogState, EventLogModelClient } from 'src/app/store/state/event-log.state'
import { LoggerService } from 'src/app/services/logger.service'
import { AuthService } from 'src/app/services/auth.service'

import 'hammerjs';

import {
  ParticipantState,
  ParticipantStateModel,
} from 'src/app/store/state/participant.state'
import {
  StudentAdmissionsState,
  StudentAdmissionsStateModel,
} from 'src/app/store/state/student-admissions.state'

@Component({
  selector: 'mu-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  idleState = 'NOT_STARTED'
  countdown?: number = null

  @Select(MenuState.selectedMenu)
  selectedMenuStream: Observable<MenuObject[]>

  @Select(ParticipantState.participant)
  participant$: Observable<ParticipantStateModel>

  @Select(StudentAdmissionsState.selectedAdmissionDetails)
  selectedAdmission$: Observable<StudentAdmissionsStateModel>

  @Select(EventLogState.clientEventLog)
  clientEvent$: Observable<EventLogModelClient>

  ngOnInit() {
    this.storageService.initializeStoreData()
    this.onOpenFromBrowser()
    this.listenToParticipantChanges()
    this.listenToSelectedMenuChanges()
    this.listenToClientEventChanges()
    this.featureFlags.loadEnabledFeature()
    this.loadFeaturesFromUrl()
    this.reset()
  }

  constructor(
    private idle: Idle,
    keepalive: Keepalive,
    cd: ChangeDetectorRef,
    public popoverController: PopoverController,
    private actionsheetCtrl: ActionSheetController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private translateService: TranslateService,
    private router: Router,
    private zone: NgZone,
    private store: Store,
    private linkService: LinkService,
    private featureFlags: FeatureFlagService,
    private loggerService: LoggerService,
    private authService: AuthService
  ) {
    idle.setIdle(environment.sessionExpiration.idleTime)
    idle.setTimeout(environment.sessionExpiration.timoutTime)
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
    idle.onIdleStart.subscribe(async () => {
      if (this.participantId) {
        this.idleState = 'IDLE'
        console.log('idle')
        const popover = await this.popoverController.create({
          component: MuKeepaliveComponent,
          cssClass: ['auto-modal', 'keepalive'],
          showBackdrop: true,
          componentProps: {
            modal: this.popoverController,
          },
        })
        return await popover.present()
      }
    })
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE'
      this.popoverController.dismiss()
      this.countdown = null
      cd.detectChanges()
    })
    idle.onTimeout.subscribe(() => {
      if (this.participantId) {
        this.idleState = 'TIMED_OUT'
        console.log('timeout')
        this.popoverController.dismiss()
        this.router.navigate(['/', 'logout']).then(() => {
          location.reload()
        })
      }
    })
    idle.onTimeoutWarning.subscribe((seconds) => (this.countdown = seconds))
    keepalive.interval(environment.sessionExpiration.keepAliveInterval)
    keepalive.onPing.subscribe(() => {
      if(!this.authService.refreshSession()) this.router.navigate(['/', 'login'])
    })
  }
  shouldRenderNativeNavigation: boolean
  faHome: IconDefinition = faHome
  faBars: IconDefinition = faBars
  faEnvelope: IconDefinition = faEnvelope
  notificationCount: number
  participantId: string
  ngUnsubscribe = new Subject()
  public menuItems: ActionSheetButton[]

  reset() {
    this.idle.watch()
    this.idleState = 'NOT_IDLE'
    this.countdown = null
  }

  onOpenFromBrowser() {
    let path: string
    let query: string
    let code: string
    App.addListener('appUrlOpen', ({ url }: { url: string }) => {
      this.zone.run(() => {
        const route = url.split('://')[0]
        if (route) {
          path = route.split('?')[0]
          query = route.split('code=')[1]
          code = query && query.split('&')[0]
        }
        if (path && code) {
          this.router.navigate(['/', path], { queryParams: { code } })
        }
      })
    })
  }

  goToHome() {
    this.navCtrl.navigateBack(['/', 'home'])
  }

  goToMessages() {
    this.router.navigate(['/', 'messages'])
  }

  private listenToSelectedMenuChanges(): void {
    this.selectedMenuStream
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((value) => !!value),
        tap((menuObjects: MenuObject[]) => {
          this.menuItems = menuObjects.map((item: MenuObject) => {
            return {
              text: item.title,
              role: 'destructive',
              handler: () => {
                this.linkService.openUrl(item.url, item.options)
              },
            }
          })
        })
      )
      .subscribe()
  }

  async presentNativeMenu() {
    const actionSheet = await this.actionsheetCtrl.create({
      header: 'Menu',
      cssClass: 'native-menu',
      buttons: [
        ...this.menuItems,
        {
          text: this.translateService.instant('elements.inbox'),
          role: 'destructive',
          icon: 'mail',
          handler: () => {
            this.router.navigate(['/', 'messages'])
          },
        },
        {
          text: this.translateService.instant('elements.help'),
          role: 'destructive',
          icon: 'help-circle',
          handler: async () => {
            await actionSheet.dismiss()
            this.store.dispatch(new ToggleHelpIsActive())
          },
        },
        {
          text: this.translateService.instant('elements.logout'),
          role: 'destructive',
          icon: 'log-out',
          handler: () => {
            this.router.navigate(['/', 'logout'])
          },
        },
      ],
    })
    await actionSheet.present()
  }

  private listenToParticipantChanges(): void {
    this.participant$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((p) => {
          this.selectedAdmission$
            .pipe(
              takeUntil(this.ngUnsubscribe),
              tap((admission) => {
                this.participantId = p.participantId
              })
            )
            .subscribe()
        })
      )
      .subscribe()
  }

  private listenToClientEventChanges(): void {
    this.clientEvent$
    .pipe(
      takeUntil(this.ngUnsubscribe),
      tap((e) => {
       if(e.log_level) {
        this.loggerService.logEvent(e)
       }  
      })
    )
    .subscribe()
  }

  private loadFeaturesFromUrl() {
    const query = window.location.search
    if (!!query) {
      const urlParams = new URLSearchParams(query)
      const ff: string[] = urlParams.getAll('ff')
      if (ff) {
        this.featureFlags.save(urlParams.getAll('ff'))
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.unsubscribe()
  }
}
